// Libs
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import type Rx from 'rx';

import { useVisibilityChange } from '@neptune/shared/common-ui';

// App
// eslint-disable-next-line no-restricted-imports
import { createPollingStream, createRetryStream, StreamError } from 'common/streamUtils';

const POLLING_RETRY_COUNT = 60;

interface UsePollingParams<T> {
  pollingFunction: () => Promise<T>;
  filterEmitsFunction?: () => boolean;
  filterErrorsFunction?: (error: StreamError) => boolean;
  interval?: number;
  deps: any[];
  onStart?: () => void;
  onSuccess?: (data: T) => void;
  onError?: (error: any) => void;
}

/**
 * @deprecated use usePolling hook from @neptune/shared/common-business-logic instead
 */
export function usePolling<T>({
  pollingFunction,
  filterEmitsFunction,
  filterErrorsFunction,
  interval,
  deps,
  onStart,
  onSuccess,
  onError,
}: UsePollingParams<T>): void {
  const subscription = React.useRef<Rx.IDisposable | null>(null);
  const retryAttempt = React.useRef<number>(0);

  function stopPolling() {
    if (subscription.current) {
      subscription.current.dispose();
      subscription.current = null;
    }
  }

  function startPolling() {
    stopPolling(); // safe-guard against running polling

    onStart?.();

    const stream = createPollingStream<T>({
      pollingFunction,
      interval,
      filterEmitsFunction,
      // handle retries by custom error stream so can't be shared
      shared: false,
      retry: false,
    })
      .retryWhen((errorsStream: any) =>
        createRetryStream({
          errorsStream,
          retryCount: POLLING_RETRY_COUNT,
          filterErrorsFunction,
          onError,
          attempt: () => ++retryAttempt.current,
        }),
      )
      .do(() => (retryAttempt.current = 0));

    subscription.current = stream.subscribe(onSuccess, onError);
  }

  React.useEffect(() => {
    startPolling();
    return stopPolling;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useVisibilityChange(
    React.useCallback((hidden: boolean) => {
      if (hidden) {
        stopPolling();
      } else {
        startPolling();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps),
  );
}
